.ospLanding {
  width: 100%;
  position: relative;
  background-color: var(--gris-20);
  overflow-x: hidden;
  align-items: flex-start;
  gap: 50px;
  line-height: normal;
  letter-spacing: normal;
  font-size: var(--font-size-base-7);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-black);
  font-family: var(--body-1);
  content-visibility: auto;
  box-sizing: border-box;
}

.texturasOsp5Icon {
  position: absolute; 
  top: 60px;
  overflow: hidden;
  width: 100%;
  height:260px; 
  object-fit: cover;
  margin: 0;
  left: 50%; 
  transform: translateX(-50%);
}

.frameChild {
  height: 408px;
  width: 1920px;
  position: relative;
  background-color: var(--color-gainsboro-100);
  display: none;
  max-width: 100%;
}

.frameItem {
  position: absolute;
  top: 131px;
  left: 0;
  width: 1812px;
  height: 41px;
  z-index: 2;
}
.frameParent {
  flex: 1;
  position: relative;
  max-width: 100%;
}

.rectangleParent {
  background: #D9D9D9;
}
@media screen and (max-width: 1125px) {
  .rectangleParent {
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
    background: #D9D9D9;
  }
 
}
@media screen and (max-width: 800px) {
  

  .ospLanding {
    gap: 22px;
  }
}
@media screen and (max-width: 450px) {
  .rectangleParent {
    padding-top: var(--padding-17xl);
    padding-bottom: 37px;
    box-sizing: border-box;
  }
 
}

.facebookChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-gainsboro);
}
.facebookIcon {
  position: absolute;
  height: 88.52%;
  width: 44.07%;
  top: 6.3%;
  right: 27.78%;
  bottom: 5.19%;
  left: 28.15%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.facebook {
  height: 27px;
  width: 27px;
  position: relative;
}


.borderDecoration{
  width: 100%;
  height: 8px;
  background-color: var(--osp);
  z-index: 50;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 10px 10px;
}
.expediente3Icon {
  height: 37px;
  width: 37px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.expediente3Wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl) 0 var(--padding-2xl);
}
.ingreseContenido {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 500;
  font-family: inherit;
  z-index: 1;
}
.direccinDelRegistro {
  flex: 1;
  position: relative;
  z-index: 1;
}
.direccinDelRegistroDelEstWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-mid) 0 var(--padding-lg);
  font-size: var(--font-size-smi-6);
}
.cardVerticalInner,
.frameParent {
  display: flex;
  justify-content: flex-start;
}
.frameParent {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-3xs-5);
}
.cardVerticalInner {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  
}
.fondoIcon {
  align-self: stretch;
  height: 9.5px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.cardVertical {
  background-color: white;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 23px 10px;
  position: relative;
  border-radius: 10px;
  border: 2px solid #eee;
  margin-bottom: 50px;
}

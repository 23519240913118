.cabeceraHeader {
  flex-direction: column;
  align-items: center;
  padding: 40px clamp(20px, 8%, 150px); 
  gap: var(--gap-6xl);
  width: 100%;
  display: flex;
}

.headerSpacingIcon {
  width: 150px;
  height: 100px;
  object-fit: contain;
  transition: width 0.3s ease, height 0.3s ease;
}

.socialButtonsContainer {
  width: 595px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-6xl);
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.socialesWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-xl);
  align-self: stretch;
}

@media screen and (max-width: 800px) {
  .cabeceraConIconos {
    padding: 40px 10px;
  }
}

@media screen and (max-width: 1350px) {
  .socialButtonsContainer {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.areaNavigationParent {
    width: 1400px;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--gap-45xl-9);
    font-size: var(--h11-size);
    color: var(--osp);
  }

.areaNavigationParent,
.ospAfiliacionesInner {
  display: flex;
  max-width: 100%;
  
}

.ospAfiliacionesInner {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-53xl-5);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-base-8);
  color: var(--gris-principal);
  font-family: var(--body-1);
}

.areaDescriptionParent,
.frameWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.frameWrapper {
    align-self: stretch;
    justify-content: flex-end;
  }





.areaDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  justify-content: space-between; 
  position: relative;
  width: 550px;
  align-items: flex-start;

}

.lineaDivisoria {
  height: auto;
  width: 2.9px;
  position: relative;
  border-right: 3px solid var(--osp);
  box-sizing: border-box;
  align-self: stretch;
  border-radius: 20px;
  margin-left: 69.5px;
  margin-right: 69.5px;
  margin-top: 50px;
}


@media screen and (max-width: 1200px) {
  .areaDescriptionParent {
    flex-wrap: wrap;
  }
  .ospAfiliacionesInner {
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
  .lineaDivisoria {
    position: relative;
    height: 2.9px; 
    width: 60%; 
    border-bottom: 2.9px solid var(--osp); 
    border-right: none; 
    margin-top: 20px; 
  }
 

}

@media screen and (max-width: 650px) {
  .areaDescription {
    width: 90%;
    font-size: 2rem; 
  }

.lineaDivisoria {
  
  width: 70%; 

}


}

@media screen and (max-width: 450px) {
  
  .areaDescription {
    width: 90%;
    font-size: 1.5rem; 
  }


.lineaDivisoria {
  margin-left: 50px;
  margin-right: 50px;
  width: 100%; 
  
}

}





.infoIcon {
  height: 49.1px;
  width: 49.1px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.infoWrapper {
  width: 71.6px;
  border-radius: 4.68px;
  background-color: var(--blanco);
  border: 0.7px solid var(--gris-70);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-4xs) var(--padding-3xs);
  cursor: pointer;
}

.infoWrapper:hover,
.infoWrapper.active {
  outline: 1px solid var(--osp);
  background-color: #F5F5F5;
}

.infoWrapper:focus {
  outline: 2px solid var(--osp);
}



.misDocumentos {
  align-self: stretch;
  height: 44.9px;
  position: relative;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}
.botonServicio {
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs-7) var(--padding-6xs);
  box-sizing: border-box;
  gap: var(--gap-3xs-8);
  text-align: center;
  font-size: var(--font-size-base-8);
  color: var(--gris-principal);
  font-family: var(--body-1);
}

.reaAfiliaciones {
  position: relative;
  font-size: inherit;
  font-weight: 700;
  text-transform: capitalize;
}
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  justify-content: space-between; 
  position: relative;
  width: 541px;
  align-self: stretch;
  justify-content: space-around;
  gap: var(--gap-14xl-8);
}


.reaAfiliacionesWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
  margin: 50px;
  flex-shrink: 0;
}

.paraPresentacinDe {
  align-self: stretch;
  position: relative;
  font-size: var(--body-1-size);
  font-weight: 700;
  color: var(--gris-principal);
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
}
.botones {
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 13px 37px;
  padding-bottom: 50px;
  justify-content: center;
  max-width: 100%;

}

.botonServicio,
.botones {
display: flex;
justify-content: center;
box-sizing: border-box;
}
.botonServicio {
width: 140px;
flex-direction: column;
align-items: center;
padding: var(--padding-5xs-7) var(--padding-6xs);
gap: var(--gap-3xs-8);

}

@media screen and (max-width: 800px) {
  .botones {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 50%;
    margin-left: 80px;
  }

}
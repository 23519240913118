.areaActionWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
  
  .areaAction {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    max-width: 100%;
    justify-items: center;
    overflow: hidden;
  }
  
  @media screen and (max-width: 1200px) {
    .areaAction {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  @media screen and (max-width: 650px) {
    .areaAction {
      grid-template-columns: 1fr;
    }
  }
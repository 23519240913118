.cardAtpWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 42px; 
  margin: 100px 0px 5px 0px;
  justify-content: center;
  align-items: center;
 
}


.frameChild,
.frameItem {
  position: absolute;
  width: 256px;
}
.frameChild {
  border-radius: var(--br-3xs);
  background-color: var(--blanco);
  height:300px;
}
.frameItem {
  border-radius: var(--br-3xs) var(--br-3xs) 0 0;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
} 

.grisPrincipal {
  background-color: var(--gris-principal);
}

.osp {
  background-color: var(--osp);
}
.rectangleParent {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: -2px;
  right: 0;
  left: 0;
}
.delegacionesIcon {
  height: 50px;
  width: 50px;
  position: relative;
  z-index: 2;
}
.delegacionesWrapper {
  align-self: stretch;
  padding: 0 var(--padding-12xs) 0 0;
  display: flex;
  justify-content: center;
}
.sectorAgropecuario {
  flex: 1;
  position: relative;
  font-weight: bold;
  font-size: var(--body-1-size);
  text-align: center;
}
.sectorAgropecuarioWrapper {
  width: 100%;
  justify-content: center;
  z-index: 2;
}
.repblicaDelLbano {
  margin: 0;
  font-weight: 300;
}
.horario0730A {
 margin-top: 15px;
 font-weight: 500;
}
.esUnSectorContainer {
  width: 100%;
  height: 104.5px;
  text-align: left;
  font-size: var(--body-10-size);
  color: var(--gris-principal);
  flex-shrink: 0;
  z-index: 2;

}

/* 
.vectorIcon {
  width: 5.7px;
  height: 4.2px;
  position: relative;
}
.ingresarCuenta {
  height: 7.1px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs-1) var(--padding-12xs);
  box-sizing: border-box;
  width: 7.1px;
}
.texto {
  position: relative;
  font-weight: 500;
  white-space: pre-wrap;
  display: inline-block;
  min-width: 66px;
} */


.botonPrincipal,
.botonPrincipalWrapper,
.cardAtp {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.botonPrincipal {
  box-shadow: 0 0 1.42px rgba(0, 0, 0, 0.25);
  border-radius: 2.49px;
  background-color: var(--gris-80);
  padding: var(--padding-8xs-2) var(--padding-2xs) var(--padding-8xs-3);
  gap: var(--gap-8xs-3);
  z-index: 2;
}
.botonPrincipalWrapper,
.cardAtp {
  padding: 0 var(--padding-44xl) 0 var(--padding-42xl);
  text-align: left;
  font-size: var(--font-size-3xs-9);
  color: var(--blanco);
}



.cardAtp {
  width: 256px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: var(--br-3xs);
  background-color: var(--blanco);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  position: relative;
  gap: var(--gap-mid);
  text-align: center;
  font-size: var(--body-1-size);
  color: var(--color-black);
  font-family: var(--body-1);
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));
  padding: var(--padding-mini) var(--padding-2xl) var(--padding-base);
}



@media (max-width: 1280px) {
  .cardAtpWrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1200px) {
  .cardAtpWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1110px) {
  .cardAtpWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .cardAtpWrapper {
    grid-template-columns: repeat(1, 1fr);

  }
 
}





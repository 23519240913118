.losTrmitesQue {
    margin-block-start: 0;
    margin-block-end: 20px;
  }
  
  .losTrmitesQueContainer {
    width: 100%;
    position: relative;
    display: inline-block;
    max-height: 238px;
    padding-right: 20px;
    word-break: break-word;
    box-sizing: border-box;
    white-space: normal;
    overflow-wrap: break-word;
    margin-top: 50px;
  }
  
  .losTrmitesQueSeRealizanPWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--padding-mid) 0 0;
    box-sizing: border-box;
    max-width: 100%;
    text-align: left;
    font-size: var(--body-1-size);
    color: var(--gris-principal);
  }
  
  @media screen and (max-width: 650px) {
    .losTrmitesQueContainer {
      width: 90%;
      font-size: 1rem; 
      margin-left: 20px;
      
    }
  }
  
  @media screen and (max-width: 450px) {
    .losTrmitesQueContainer {
      width: 90%;
      font-size: 0.8rem;
      margin-left: 20px;
      text-rendering: auto;
    }
  }
.carouselContainer {
  width: 100%;
  margin: 50px auto;
  position: relative;
  overflow:visible;
  align-items: center; 
  justify-content: center; 
  box-sizing: border-box;
}



.carousel {
  width: 100%;
  height: 100%;
  position: relative;
}

.carouselItem {

  width: 100%;
  height: 100%;
}

.imageWrapper {
  width: 1668px;
  height: 295px;
  position: relative;
  object-fit: cover;
  top: 0;
  margin: 0 auto;
  
}



/* Estilos para las flechas de navegación */
.carousel :global(.carousel-control-prev),
.carousel :global(.carousel-control-next) {
 width: 72px;
  height: 41px;
  background-color: transparent;
  top: 50%;
  transform: translateY(-50%);
}

.carousel :global(.carousel-control-prev) {
  left: 0;
}

.carousel :global(.carousel-control-next) {
  right: 0;
}

.carousel :global(.carousel-control-prev-icon),
.carousel :global(.carousel-control-next-icon) {
  width: 30px;
  height: 30px;
  background-size: 100% 100%;
}

.carousel :global(.carousel-control-prev-icon) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.carousel :global(.carousel-control-next-icon) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

/* Estilos para los indicadores */
.carousel :global(.carousel-indicators) {
  bottom: -25px;
}

.carousel :global(.carousel-indicators [data-bs-target]) {
  width: 30px;
  height: 3px;
  background-color: #000;
  opacity: 0.5;
  border: none;
  margin: 0 3px;
}

.carousel :global(.carousel-indicators .active) {
  opacity: 1;
}

/* Estilos responsivos */
@media screen and (max-width: 1920px) {
  .carouselContainer {
    padding: 0 70px;
    
  }

  .imageWrapper {
    width: calc(100% - 144px);
    height: 0;
    padding-bottom: calc((295 / 1668) * (100% - 144px));
  }
}
@media screen and (max-width: 622px) {
  .carouselContainer {
    margin: 25px auto;
    padding: 0;
  }

  .imageWrapper {
    width: calc(100% - 80px); 
    height: 0;
    object-fit: cover;
    padding-bottom: calc((800 / 450) * (100% - 80px));
    position: relative;
  }

  .carousel :global(.carousel-control-prev),
  .carousel :global(.carousel-control-next) {
    width: 40px;
    top: 50%;
  }

  .carousel :global(.carousel-indicators) {
    bottom: -30px;
  }
}
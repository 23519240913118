.instagramChild {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
}
.instgramIcon {
  position: absolute;
  height: 88.52%;
  width: 88.52%;
  top: 6.3%;
  right: 5.19%;
  bottom: 5.19%;
  left: 6.3%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.instagram {
  height: 27px;
  width: 27px;
  position: relative;
}

.ospLandingInner {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-xl) var(--padding-6xs);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-base-8);
  color: var(--gris-principal);
  font-family: var(--body-1);

}

.cabeceraConIconosParent {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap-17xl);
  padding-left: clamp(20px, 10%, 235px); 
  padding-right: clamp(20px, 10%, 235px); 
  box-sizing: border-box;
} 
.cabeceraConIconos {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xl);
  background-color: var(--blanco);
  flex-direction: column;
  align-items: center;
  padding: 40px clamp(20px, 8%, 150px); 
  box-sizing: border-box;
  gap: var(--gap-6xl);
  width: 100%;
  max-width: 1450px;
  z-index: 1;
}
.cabeceraConIconosParent,
.ospLandingInner {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}




@media screen and (max-width: 800px) {
  .cabeceraConIconos {
    padding: 40px 10px;
  }

  .cabeceraConIconosParent {
    gap: var(--gap-lg);
    padding-left: 10px; 
    padding-right: 10px; 
  }
}






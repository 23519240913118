.carouselContainer {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom: 50px;
   
  }
  
  .carouselItem {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; 
    transition: all 0.3s ease;
   
  }
  
  .videoContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 5px;
    box-sizing: border-box;
    max-width: 50%;
    margin-right: -30px;
    
    
  }
  
  
  .videoThumbnail {
    width: 60%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
   
  }
  
  .descriptionContainer {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    padding-right: 100px;
    max-width: 50%;

  }
  
  .descriptionContainer h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--osp);
  }
  
  .descriptionContainer p {
    font-size: 16px;
  }
  
  .modalVideo {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  @media (max-width: 1024px) {
    .carouselItem {
      flex-direction: column;
    }
  
    .videoContainer {
      max-width: 100%; 
      min-width: 700px; 
      margin: 0 auto;
      padding: 10px;
    }
  
    .descriptionContainer {
      max-width: 90%; 
      text-align: center;
      padding: 20px;
      padding-right: 0;
    }
  
    .videoThumbnail {
      width: 100%;
      height: auto; 
      min-height: 200px;
    }
  }
  
  
  /* Media Query para pantallas pequeñas (móviles) */
  @media (max-width: 768px) {
    .carouselItem {
      flex-direction: column;
    }
  
    .videoContainer {
      max-width: 100%; 
      min-width: 400px; 
      margin: 0 auto;
      padding: 5px;
      
     
    }
  
    .descriptionContainer {
      max-width: 80%;
      padding: 25px;
      text-align: center;
      padding-right: 50px;
    }
  
    .videoThumbnail {
      width: 100%; 
      height: auto;
      min-height: 200px; 
      border-radius: 0;
      margin-right: 50px;
    }
  }

  @media (max-width: 1115px) {
    .videoThumbnail {
        width: 75%;
        margin-left: 35px;
      }
    
  }


.footer {
  background-color: #333;
  width: 100%;
  padding: 50px 235px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 190px;
  gap: 96px;
  box-sizing: border-box;

}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 96px;
  height: 90px;
  width: 100%;
}

.logoBlock {
  width: 145px;
  height: 57px;
  flex-shrink: 0;
}

.logoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.groupIcon, .groupIcon1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.groupIcon {
  right: 0;
  width: 52.92%;
  height: 50.7%;
}

.groupIcon1 {
  left: 0;
  width: 39.24%;
  height: 100%;
}


.infoBlock {
  display: flex;
  align-items: center;
  text-align: left;
  flex-grow: 1;
  height: 100%;


}

.institucionalBlancoIcon {
  margin-right: 15px;
}

.infoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.infoText p {
  margin: 5px 0;
  font-weight: 300;
}

.socialBlock {
  display: flex;
  align-items: center;
  height: 100%;
 
}
.socialBlock > * {
  cursor: pointer;
}

@media screen and (max-width: 1125px) {
  .footer {
    padding: 50px 60px;
  }
}

@media screen and (max-width: 800px) {
  .footer {
    padding: 30px;
    height: auto;
  }

  .footerContent {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    height: auto;
  }

  .logoBlock, .infoBlock, .socialBlock {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .infoBlock {
    flex-direction: column;
  }

  .institucionalBlancoIcon {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .infoText {
    align-items: center;
  }

  .logoBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoWrapper {
    width: 145px;
    height: 57px;
  }
}

.sociales {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 var(--padding-9xs) 0 0;
  gap: 19px 17.7px;
}

.sociales div:hover{
  cursor: pointer;
}
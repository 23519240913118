
.busquedaParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  gap: 5px;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.busqueda {
  flex: 1;
  border-radius: var(--br-6xs);
  background-color: var(--blanco);
  border: 1px solid var(--gris-principal);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-xs);
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
  
}
.queTrmiteO {
    width: calc(100% - 60px);
    border: 0;
    outline: 0;
    font-weight: 500;
    font-family: var(--body-1);
    font-size: var(--font-size-smi);
    background-color: transparent;
    height: 16px;
    flex: 1;
    position: relative;
    color: var(--gris-principal);
    text-align: left;
    display: flex;
    align-items: center;
    min-width: 145px;
    padding: 0;
    z-index: 2;
}

.lupa2Icon {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

}